import React from 'react'
import SectionButton from './../SectionButton'
import { navigate } from '@reach/router'
import './styles.css'

function Announcement(props) {
  return (
    <div
      className={
        'message is-marginless' +
        (props.color ? ` is-${props.color}` : '') +
        (props.size ? ` is-${props.size}` : '')
      }
    >
      <div className="Announcement__message-header message-header is-radiusless">
        <div className="Announcement__text">{props.text}</div>
        {props.buttons.map((button, index) => (
          <SectionButton
            key={index}
            parentColor={props.color}
            size={props.size}
            onClick={() => {
              navigate(button.buttonOnClick)
            }}
          >
            {button.buttonText}
          </SectionButton>
        ))}
      </div>
    </div>
  )
}

export default Announcement

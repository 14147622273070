import React from 'react'
import Section from './../Section'
import SectionHeader from './../SectionHeader'
import NewsletterSection from './../NewsletterSection'
import './styles.scss'

function HeroSection(props) {
  const content = props.data.edges[0].node
  const styles = props.styles
  const location = props.location

  return (
    <Section color={props.color} size={props.size}>
      <div className="container">
        <div className="columns is-vcentered is-desktop">
          <div className="column is-5-desktop has-text-centered-touch">
            <SectionHeader
              title={content.title}
              subtitle={content.subtitle}
              size={1}
            />
            {/* <SectionButton
              parentColor={props.color}
              size="medium"
              onClick={props.buttonOnClick}
            >
              {props.buttonText}
            </SectionButton> */}
            <NewsletterSection
              centered={false}
              size={4}
              buttonText={props.buttonText}
              subscribedMessage={props.subscribedMessage}
              inputPlaceholder={'Email Address'}
              formName="hero"
            />
          </div>
          <div className="column is-1" />
          <div className="column">
            <figure className="HeroSection__image image">
              <img src={props.image} alt="Illustration" />
            </figure>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default HeroSection
